
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import MeterManagementSection from '@/components/sections/dashboard/MeterManagementSection.vue'

export default defineComponent({
  name: 'MeterManagement',
  components: {
    Main,
    MeterManagementSection
  }
})
