
import { defineComponent } from 'vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import authHeader from '@/services/auth-header'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'MeterManagementSection',
  data () {
    return {
      editData: false,
      openPopup: false,
      openPopupDelete: false,
      deleteChildId: null,
      mark: Math.floor(Math.random() * (8 - 5) + 5),
      usersData: {},
      marks: [
        {
          number: 1,
          hidden: true
        },
        {
          number: 1.5
        },
        {
          number: 2,
          hidden: true
        },
        {
          number: 2.5
        },
        {
          number: 3,
          hidden: true
        },
        {
          number: 3.5
        },
        {
          number: 4,
          hidden: true
        },
        {
          number: 4.5
        },
        {
          number: 5,
          hidden: true
        },
        {
          number: 5.5
        },
        {
          number: 6,
          hidden: true
        },
        {
          number: 6.5
        },
        {
          number: 7,
          hidden: true
        },
        {
          number: 7.5
        },
        {
          number: 8,
          hidden: true
        },
        {
          number: 8.5
        },
        {
          number: 9,
          hidden: true
        }
      ],
      errorEdit: null,
      balance: null
    }
  },
  components: {
    DefaultPopup,
    AlertBlock
  },
  mounted () {
    this.getUsers()
    this.getBalance()
  },
  methods: {
    getBalance () {
      this.$store.dispatch('getPayments/getBalance').then((res) => {
        this.balance = res.data.coins
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    getUsers () {
      this.$store.dispatch('getPayments/getUsers').then((res) => {
        this.usersData = res.data.data
      },
      (error) => {
        this.message = error.response.data.errors
      })
    },
    editMark (id) {
      this.editData = id
    },
    editMarkMobile (id) {
      this.openPopup = true
      this.editData = id
    },
    saveMark (mark, id) {
      this.mark = mark
      const API_URL = process.env.VUE_APP_URL
      this.axios.put(API_URL + 'parent/users/' + `${id}`, {
        id: id,
        mark: mark
      }, { headers: authHeader() }).then(() => {
        this.getUsers()
        this.editData = false
      },
      (error) => {
        this.errorEdit = error.response.data.errors
      })
    },
    deleteChild (id) {
      this.editData = false
      const API_URL = process.env.VUE_APP_URL
      this.axios.delete(API_URL + 'parent/users/' + `${id}`, { headers: authHeader() }).then(() => {
        this.getUsers()
      },
      (error) => {
        this.errorEdit = error.response
      })
    },
    deleteChildPopup (id) {
      this.openPopupDelete = true
      this.deleteChildId = id
    },
    saveMarkMobile (mark, id) {
      const API_URL = process.env.VUE_APP_URL
      this.axios.put(API_URL + 'parent/users/' + `${id}`, {
        id: id,
        mark: mark
      }, { headers: authHeader() }).then(() => {
        this.getUsers()
        this.editData = false
        this.openPopup = false
      },
      (error) => {
        this.errorEdit = error.response.data.errors
      })
    },
    closePopup () {
      this.openPopup = false
      this.editData = false
    },
    closePopupDelete () {
      this.openPopupDelete = false
    }
  }
})
