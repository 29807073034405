
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DefaultPopup',
  props: {
    onClose: {
      type: Function,
      default () {
        return {}
      }
    },
    message: {
      type: String
    }
  },
  components: {
  },
  methods: {
    handleCloseModal () {
      this.onClose()
    },
    copyLink () {
      var copyLink = document.getElementById('ref') as HTMLInputElement
      copyLink.select()
      document.execCommand('copy')
    }
  }
})
